@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-poppins ;
    }
}

